import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {File} from "@awesome-cordova-plugins/file/ngx";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {UploadService} from "./services/upload.service";
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { NewProjectModalComponent } from './new-project-modal/new-project-modal.component';

@NgModule({
  declarations: [AppComponent, NewProjectModalComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, FormsModule, HttpClientModule, ReactiveFormsModule, NgxIonicImageViewerModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, File, UploadService, PhotoViewer],
  bootstrap: [AppComponent],
})
export class AppModule {}
