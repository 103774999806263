import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-new-project-modal',
  templateUrl: './new-project-modal.component.html',
  styleUrls: ['./new-project-modal.component.scss'],
})
export class NewProjectModalComponent implements OnInit {
  private newEvent : FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private modalCtrl: ModalController
  ) {
    this.newEvent = this.formBuilder.group({
      eventType: [''],
      description: [''],
      date: [''],
      location: [''],
      password: ['']
    });
   }

  ngOnInit() {}

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  public createNewProject() {
    console.log('eventType', this.newEvent.value['eventType']);
    console.log('description', this.newEvent.value['description']);
    console.log('date', this.newEvent.value['date']);
    console.log('location', this.newEvent.value['location']);
    console.log('password', this.newEvent.value['password']);

    this.http.post('https://niaoj8os2j.execute-api.eu-central-1.amazonaws.com/dev/createProject', {"eventType": this.newEvent.value['eventType'], description: this.newEvent.value['description'], date: this.newEvent.value['date'], location: this.newEvent.value['location'], password: this.newEvent.value['password']
}).subscribe( async (data:any) => {
      console.log('data',data);
      if(JSON.parse(data).status === 200) {
          await this.modalCtrl.dismiss();
      }
    });

  }

}
